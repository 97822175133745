<div class="container-fluid" *ngIf="user && user.type !== 'user'">
  <app-pagetitle title="Tableau de bord"></app-pagetitle>
  <div class="row">
    <div class="col-xl-12">
      <div class="row">
        <div class="col-md-4">
          <div class="card card-body">
            <h3 class="card-title mt-0">Nombre de projets en cours</h3>
            <p class="card-text"> {{statistics.projectsInprogress || 0}}
              {{statistics.projectsInprogress > 1 ? 'projets' : 'projet'}}</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card card-body">
            <h3 class="card-title mt-0">Nombre de projets finis</h3>
            <p class="card-text">{{statistics.projectsFinished || 0}}
              {{statistics.projectsFinished > 1 ? 'projets' : 'projet'}}</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card card-body">
            <h3 class="card-title mt-0">Nombre de produits proposés</h3>
            <p class="card-text">{{statistics.totalProducts || 0}}
              {{statistics.totalProducts > 1 ? 'produits' : 'produit'}}
            </p>
          </div>
        </div>
        <div class="col-md-4" *ngIf="user && (user.type === 'customer' || user.type === 'admin')">
          <div class="card card-body">
            <h3 class="card-title mt-0">Nombre d'utilisateurs</h3>
            <p class="card-text">{{statistics.users || 0}}
              {{statistics.users > 1 ? 'utilisateurs' : 'utilisateur'}}
            </p>
          </div>
        </div>
        <div class="col-md-4" *ngIf="user && (user.type === 'customer' || user.type === 'admin')">
          <div class="card card-body">
            <h3 class="card-title mt-0">Total des projets</h3>
            <p class="card-text">{{statistics.totalPriceProjects || 0}} €</p>
          </div>
        </div>
        <div class="col-md-4" *ngIf="user && (user.type === 'customer' || user.type === 'admin')">
          <div class="card card-body">
            <h3 class="card-title mt-0">Total des produits</h3>
            <p class="card-text">{{ statistics.totalPriceProducts || 0}} €</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container pt-3" *ngIf="user && user.type === 'user'">
  <div class="row jet-font">
    <div class="col-6 col-md-6 col-sm-8">
      <a class="btn btn-account" href="./profil">
        <span class="d-block d-sm-none" ><i class="fas fa-user-circle"></i></span>
        <span class="d-none d-sm-block" >Bonjour <b>{{ user.fullname }} </b><i class="fas fa-user-circle"></i></span>
      </a>

    </div>
    <div class="col col-md-6 col-sm-2 text-right">
      <!-- <div (click)="logout()" class="logout">Déconnexion</div> -->
      <div (click)="logout()" class="btn btn-logout">
        <span class="d-block d-sm-none" ><i class="fas fa-sign-out-alt"></i></span>
        <span class="d-none d-sm-block" ><i class="fas fa-sign-out-alt"></i>Déconnexion</span>
      </div>
    </div>
  </div>

  <div class="card mt-4" *ngIf="projects.length">
    <div class="card-body">
      <h6 class="mb-4 jet-font">Liste des projets</h6>

      <div class="table-responsive">
        <table class="table table-striped">
          <tr class="jet-font">
            <th width="150">Référence</th>
            <th>Pièce</th>
            <th width="150">Statut</th>
            <th width="240">Date de création</th>
            <th width="150"></th>
          </tr>
          <tr *ngFor="let project of projects">
            <td>
              <a *ngIf="project.status == 'new'" [routerLink]="['/apercu', project.id]">{{ project.reference }}</a>
              <a *ngIf="project.status != 'new'"
                [routerLink]="['/projets/utilisateur/', project.id]">{{ project.reference }}</a>
            </td>
            <td> {{ project.title }} </td>
            <td> {{ getStatusName(project.status) }} </td>
            <td> {{ project.created_at | date:'dd MMMM yyyy à HH:ss' }} </td>
            <td>
              <a *ngIf="settings.enabled_payment == 1 && project.status == 'new'"
                [routerLink]="['/paiement/', project.id]" style="margin-bottom: 5px;" class="btn btn-primary btn-sm jet-font mr-3">Payer</a>
                <div *ngIf="project.status == 'new'" style="margin-bottom: 5px;" (click)="deleteProject( project.id )" class="btn btn-sm btn-rounded btn-danger"> <i class="fa fa-trash"></i></div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>

  <div class="text-center pt-5 pb-5">
    <p class="w-75 m-auto pb-5">
      Vous souhaitez revoir l'aménagement de votre salon ? décorer votre chambre ? …
      Vous manquez d'inspiration ou de temps pour le choix de matériaux, de mobilier, d'objets décoratifs ...
      Consultez un décorateur, obtenez une recommandation personnalisée et concrétisez votre projet !
    </p>

    <button (click)="startNewProject()" class="btn btn-primary jet-font">Lancer mon coaching déco</button>
  </div>
  <div class="pt-5" *ngIf="user.type === 'user'">
    <div class="footer"  style="left: 0px;">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-4">
            <div class="text-sm-left">
              <a href="/assets/Koachy_Politique_de_confidentialite.pdf" target="_blank">Politique de confidentialité</a>
              <!-- <p>Politique de confidentialité</p> -->
            </div>
          </div>
          <div class="col-sm-4 text-center">
            <a href="javascript:void(0);" (click)="this.contact();">
              <i class="fas fa-envelope"></i>
              Contactez-nous</a>
          </div>
          <div class="col-sm-4">
            <div class="text-sm-right">
              <a href="/assets/Koachy_CGVU_conforama.pdf" target="_blank">Conditions générales de vente et d'utilisation</a>
              <!-- <p>Conditions générales de vente et d'utilisation</p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
